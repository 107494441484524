import React from 'react'
import AdSense from 'react-adsense'

const AdBlock = () => (
    <AdSense.Google
        client="pub-6332593219810915"
        slot="4161938787"
        style={{ display: 'block' }}
        layout="in-article"
        format="fluid"
    />
)

export default AdBlock